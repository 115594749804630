import React, { useState } from 'react'
import PropTypes from "prop-types"
import { ContactButton } from "../elements/button"
import { Link } from "gatsby"
// import { flyoutMenu } from "../../data/header"
import HeaderLink from "./header_link"
import MobileMenu from "./mobile_menu"

const Header = ({ siteTitle }) => {

  const [currentMenuId, setCurrentMenuId] = useState(null)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  return (
    // <header aria-hidden="true" onMouseLeave={() => setCurrentMenuId(null)}>
    <header aria-hidden="true">
      <div className="relative bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 py-7">
          <div className="flex justify-between items-center md:justify-start">
            <div className="-mr-2 -my-2 lg:hidden">
              <button onClick={() => setOpenMobileMenu(!openMobileMenu)} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                <span className="sr-only">Open menu</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              {
                openMobileMenu && <MobileMenu setOpenMobileMenu={setOpenMobileMenu} />
              }
            </div>
            <div className="flex justify-center w-full lg:w-auto lg:justify-start md:pr-8 lg:pr-20">
              <Link to="/">
                <span className="sr-only">Stirrup</span>
                <img className="h-8 w-auto my-auto sm:h-9" src="https://res.cloudinary.com/cryptr/image/upload/v1637475974/Stirrup/stirrup_logo_gimj4e.svg" alt="Stirrup Logo" />
              </Link>
            </div>
            <nav className="relative hidden lg:flex space-x-10">
              <HeaderLink 
                type="link"
                MenuTitle="Concept"
                path="/concept"
                setCurrentMenuId={setCurrentMenuId}
                currentMenuId={null}
              />
              {/* <>
                {flyoutMenu.map((menu, index) => {
                  return (
                    <HeaderLink 
                      key={index}
                      type="menu"
                      index={index}
                      path={menu.path}
                      MenuTitle={menu.title}
                      setCurrentMenuId={setCurrentMenuId}
                      currentMenuId={menu.id}
                      menuLinks={menu.links}
                      isMenuOpen={currentMenuId === menu.id}
                    />
                  )
                })}
              </> */}
            </nav>
            <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0 space-x-8">
              {/* <ButtonSecondary text="Connextion" size="regular" path="/" />
              <ButtonDefault text="Inscription" size="regular" path="/" /> */}
              <ContactButton text="Contactez-nous" size="regular" />
            </div>
          </div>
        </div>
        
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
