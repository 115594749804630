import * as React from "react"
import { ContactButton } from "../components/elements/button"

const Footer = ({title, contactButton}) => (
  <footer className="bg-white">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 py-7 md:flex md:items-center md:justify-between">

      <div className="flex flex-col w-full">

        <div className="relative">
          <div className="absolute inset-0">
            <img className="h-96 w-full object-contain" src="https://res.cloudinary.com/cryptr/image/upload/v1637510503/Stirrup/stirrup-footer-illustration_anrigj.jpg" alt="" />
          </div>
          <div className="relative mt-32 sm:mt-24 lg:mt-16 px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h3 className="sm:space-y-6 text-center text-xl font-semibold sm:text-2xl lg:text-3xl">
              <span className="block text-skin-default">{title.span_1}</span>
              <span className="block text-transparent bg-gradient-to-r bg-clip-text from-skin-pink via-skin-red to-skin-orange">{title.span_2}</span>
            </h3>
            <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
              <div className="space-y-4 sm:space-y-0 sm:mx-auto flex items-center justify-center">
                <ContactButton text={contactButton} size="regular" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="flex justify-center space-x-6 md:order-2">
            <a href="https://www.facebook.com/stirrup" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/stirrup" target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Linkedin</span>
              <svg className="h-6 w-6" viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.35156 0C4.38094 0 0.351562 4.02938 0.351562 9C0.351562 13.9706 4.38094 18 9.35156 18C14.3222 18 18.3516 13.9706 18.3516 9C18.3516 4.02938 14.3222 0 9.35156 0ZM7.14844 12.7303H5.32594V6.86531H7.14844V12.7303ZM6.22594 6.14531C5.65031 6.14531 5.27812 5.7375 5.27812 5.23312C5.27812 4.71844 5.66156 4.32281 6.24937 4.32281C6.83719 4.32281 7.19719 4.71844 7.20844 5.23312C7.20844 5.7375 6.83719 6.14531 6.22594 6.14531ZM13.8047 12.7303H11.9822V9.48C11.9822 8.72344 11.7178 8.20969 11.0588 8.20969C10.5553 8.20969 10.2563 8.5575 10.1241 8.89219C10.0753 9.01125 10.0631 9.18 10.0631 9.34781V12.7294H8.23969V8.73563C8.23969 8.00344 8.21625 7.39125 8.19187 6.86437H9.77531L9.85875 7.67906H9.89531C10.1353 7.29656 10.7231 6.73219 11.7066 6.73219C12.9056 6.73219 13.8047 7.53562 13.8047 9.2625V12.7303Z"/>
              </svg>
            </a>
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">
              &copy; 2021 Stirrup. All right reserved.
            </p>
          </div>
        </div>

      </div>
    </div>
  </footer>
)

export default Footer
