/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, dataFooter }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  if (!dataFooter) return null
  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>STIRRUP | Une alternative aux logements vacants</title>
        <meta name="title" content="STIRRUP | Une alternative aux logements vacants" />
        <meta name="description" content="Stirrup est une plateforme qui permet aux personnes sans-abri d'avoir accès à un logement pour une période tremplin d'accompagnement et aux propriétaires de ces logements vacants de faire d’importantes économies en toute sécurité" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://stirrup.fr/" />
        <meta property="og:title" content="STIRRUP | Une alternative aux logements vacants" />
        <meta property="og:description" content="Stirrup est une plateforme qui permet aux personnes sans-abri d'avoir accès à un logement pour une période tremplin d'accompagnement et aux propriétaires de ces logements vacants de faire d’importantes économies en toute sécurité" />
        <meta property="og:image" content="https://res.cloudinary.com/cryptr/image/upload/v1637667177/Stirrup/stirrup-meta-image_eh96kl.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://stirrup.fr/" />
        <meta property="twitter:title" content="STIRRUP | Une alternative aux logements vacants" />
        <meta property="twitter:description" content="Stirrup est une plateforme qui permet aux personnes sans-abri d'avoir accès à un logement pour une période tremplin d'accompagnement et aux propriétaires de ces logements vacants de faire d’importantes économies en toute sécurité" />
        <meta property="twitter:image" content="https://res.cloudinary.com/cryptr/image/upload/v1637667177/Stirrup/stirrup-meta-image_eh96kl.jpg" />
      </ Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <Footer 
        title={dataFooter.title}
        contactButton={dataFooter.contact_button}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
