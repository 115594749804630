import React from 'react'
import { Link } from "gatsby"
import { ContactButton } from "../../elements/button"
// import { flyoutMenu } from "../../../data/header"
// import Subnav from "./subnav"

const MobileMenu = ({setOpenMobileMenu}) => {

  return (
    <div className="absolute z-10 top-0 inset-x-0 transition transform origin-top-right md:hidden">
      <div className="shadow-lg bg-white">
        <div className="pt-7 pb-6 px-6">
          <div className="flex flex-row">
            <div className="-ml-2 -mt-1">
              <button 
                onClick={() => setOpenMobileMenu(false)} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Close menu</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <Link to="/" className="flex-1 flex justify-center">
              <span className="sr-only">Stirrup</span>
              <img className="h-8 w-auto mb-0 sm:h-9" src="https://res.cloudinary.com/cryptr/image/upload/v1637475974/Stirrup/stirrup_logo_gimj4e.svg" alt="Stirrup Logo" />
            </Link>
          </div>
          <div className="mt-6">
            <nav className="grid">
              <Link to="/concept" className="py-6 border-b border-gray-50">
                <div className="flex flex-row justify-between">
                  <span className="text-skin-default text-base font-semibold">
                    Concept
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-skin-orange" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </Link>
              {/* <>
                {
                  flyoutMenu.map((menu, index) => {
                    return (
                      <Subnav
                        key={index}
                        menuIndex={index}
                        menuLength={flyoutMenu.length}
                        menuTitle={menu.title}
                        links={menu.links}
                      />

                    )
                  })
                }
              </> */}
            </nav>
          </div>
        </div>
        <div className="py-6 px-8 space-y-6">
          <ContactButton text="Contactez-nous" size="regular" fullWidth />
        </div>
      </div>
    </div>      
  )
}

export default MobileMenu
