import { Link } from "gatsby"
import React from 'react'
import FlyoutMenu from "./flyout_menu"

const HeaderLink = ({
    MenuTitle, 
    index, 
    setCurrentMenuId, 
    currentMenuId, 
    isMenuOpen, 
    type, 
    path, 
    menuLinks
  }) => {  

  return (
    <div 
      className="relative"
      aria-hidden="true"
      onMouseEnter={() => setCurrentMenuId(currentMenuId)}
      onAnimationEnd={() => setCurrentMenuId(null)}
    >     
      {type === "link" ? (
        <Link to={path} className="cursor-pointer text-sm font-medium text-skin-default hover:text-skin-default-hover">
          {MenuTitle}
        </Link>
      ) : (
        
        <span
          className="relative text-sm font-medium text-skin-default hover:text-skin-default-hover"
        >
          {MenuTitle}
        </span>
      )}     
      {isMenuOpen && <FlyoutMenu flyoutMenuData={menuLinks} size={index === 2 ? "max-w-xs" : "max-w-md"}/>}
    </div>       
  )
}

export default HeaderLink
