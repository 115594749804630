import React from 'react'
import { Link } from "gatsby"

const FlyoutMenu = ({flyoutMenuData, size}) => {

  return (
    <div className={`absolute z-10 mt-3 px-2 w-screen ${size} sm:px-0`}>
      <div className="rounded-lg shadow-2xl overflow-hidden">
        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            {flyoutMenuData.map((menuItem, index) => {
              return (
                <Link key={index} to={menuItem.path} className="group -m-3 p-3 flex flex-row items-center rounded-lg hover:bg-gray-50">
                  {menuItem.icon}
                  <div className="ml-4">
                    <p className="mb-2 text-base font-medium text-skin-default group-hover:text-skin-pink">
                      {menuItem.title_link}
                    </p>
                    <p className="mb-0 text-sm text-skin-base">
                      {menuItem.description_link}
                    </p>
                  </div>
                </Link>
              )
            })}
        </div>
      </div>
    </div>            
  )
}

export default FlyoutMenu
