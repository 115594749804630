import * as React from "react"
import { Link } from "gatsby"

const btnRegularStyle = "px-8 py-2 text-sm"
const btnLargeStyle = "px-11 py-5 text-base"

export const ButtonDefault = ({text, path, size}) => (
  <Link to={path} className={`${size === "regular" ? btnRegularStyle : btnLargeStyle } whitespace-nowrap inline-flex items-center justify-center border-2 border-transparent rounded-full shadow-sm font-semibold text-white bg-skin-button-default hover:bg-skin-button-default-hover hover:border-skin-default hover:text-skin-default transition duration-300 ease-in-out`}>
    {text}
  </Link>
)

export const ButtonSecondary = ({text, path, size}) => (
  <Link to={path} className={`${size === "regular" ? btnRegularStyle : btnLargeStyle } whitespace-nowrap font-semibold rounded-full border-2 border-skin-default text-skin-default hover:text-white bg-skin-button-secondary hover:bg-skin-button-secondary-hover transition duration-300 ease-in-out`}>
    {text}
  </Link>
)

export const ContactButton = ({text, size, fullWidth}) => (
  <a href="mailto:contact@stirrup.fr" className={`${size === "regular" ? btnRegularStyle : btnLargeStyle } ${fullWidth ? "w-full" : "w-auto"} whitespace-nowrap inline-flex items-center justify-center border-2 border-transparent rounded-full shadow-sm font-semibold text-white bg-skin-button-default hover:bg-skin-button-default-hover hover:border-skin-default hover:text-skin-default transition duration-300 ease-in-out`}>
    {text}
  </a>
)